html,
body {
  background-color: #F8F9FA;
}

.logout-btn {
  margin-left: 300px;
}

.tooltip {
  width: 300px;
}

.scroll {
  overflow-x: auto
}

.table-class {
  table-layout: auto !important;
  width: auto !important;
}

.width-full {
  width: 90%;
  margin: 1em auto
}

/*body {
  */
  /*display: -ms-flexbox;*/
  /*display: -webkit-box;*/
  /*  display: flex;*/
  /*-ms-flex-align: center;*/
  /*-ms-flex-pack: center;*/
  /*-webkit-box-align: center;*/
  /*  align-items: center;*/
  /*-webkit-box-pack: center;*/
  /*  justify-content: center;*/
  /*padding-top: 40px;*/
  /*padding-bottom: 40px;*/
  /*  background-color: #f5f5f5;*/
  /*}*/
